<template>
  <div>
    <!-- 角色联系人关系图 -->
    <!-- 顶部标题 -->
    <div class="top-title">
      <router-link :to="$store.state.structureBeforeClickSavePath">
        {{ objName }} </router-link
      >/
      <router-link to @click.native="$router.back(-1)">
        {{ $route.params.bigTitle }}
      </router-link>
    </div>
    <!-- 主体 -->
    <div class="body">
      <!-- 主体部分 -->
      <div class="content">
        <!-- 描述信息 -->
        <div class="describe">
          <!-- 关系网 -->
          <div class="describe-tit">
            <strong>{{ $t("vue_label_commonobjects_detail_network") }}</strong>
          </div>
          <div class="describe-bd">
            <!-- 利用雷达图与多个指标，衡量联系人在销售决策链中的作用 -->
            {{ $t("label_radar_map_function") }}
          </div>
        </div>
        <!-- 联系人组织架构图 -->
        <!-- zoom -->
        <org-chart :datasource="chartData">
          <template slot-scope="{ nodeData }">
            <b>
              <a :href="`#/commonObjects/detail/${nodeData.pathid}/DETAIL`">
                {{ nodeData.name }}
              </a>
            </b>
          </template>
        </org-chart>
      </div>
      <!-- 底部示例图片 -->
      <div class="bottom-example">
        <div class="img" :style="backgroundComputed"></div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 人际关系图
 */
import * as structureChart from "./api.js";
// 获取权限请求（返回是否有权限打开详情页）
export default {
  data() {
    return {
      chartData: {},
      objId: "", //当前对象id
      // 左上角面包屑对象
      // breadObj: {
      //   objName: "", //当前对象名称（左上角面包屑name）
      //   objPath: "", //当前对象的商机
      // },
    };
  },
  created() {
    // 当前所打开的对象id
    this.objId = this.$route.params.objId;
    this.display();
  },
  components: {
    // 架构图
    OrgChart: () => import("@/components/OrgChart"),
  },
  methods: {
    // 获取业务机会所属数据
    async display() {
      if (this.objId === "account") {
        // 客户
        this.objName = "客户";
        document.title = this.$setTitle(`${this.objName} | 关系网`);
        let { data } = await structureChart.display({
          accountId: this.$route.params.opportunityId,
          // opportunityId: '002202004390E51ftWui'
        });
        this.chartData = data ? data : {};
      } else if (this.objId === "opportunity") {
        // 业务机会
        this.objName = "业务机会";
        document.title = this.$setTitle(`${this.objName} | 关系网`);
        let { data } = await structureChart.display({
          opportunityId: this.$route.params.opportunityId,
          // opportunityId: '002202004390E51ftWui'
        });
        this.chartData = data ? data : {};
      } else {
        return;
      }
    },
  },
  computed: {
    backgroundComputed() {
      let { language } = this.$store.state.userInfoObj;
      if (language === "zh") {
        // 中文

        return {
          backgroundImage:
            "url(" +
            require("@/assets/svgImgFiles/structureChart/structureChartexample.svg") +
            ")",
        };
      } else {
        return {
          backgroundImage:
            "url(" +
            require("@/assets/svgImgFiles/structureChart/en_structureChartexample.svg") +
            ")",
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top-title {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: #fafaf9;
  font-size: 18px;
}
/* 主体 */
.body {
  height: calc(100vh - 50px - 99px);
  overflow: auto;
  background-color: #fafaf9;
  /* 主体部分 */
  .content {
    background-color: #fff;
    padding: 10px 20px;
    overflow: auto;
  }
  .describe {
    .describe-tit {
      font-size: 16px;
    }
    .describe-bd {
      margin-top: 5px;
      font-size: 12px;
    }
  }

  /* 底部示例 */
  .bottom-example {
    margin-top: 10px;
    background-color: #fff;
    height: 394px;
    .img {
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 80%;
    }
  }
}
</style>
